@import '../../styles/variables';
@import '../../styles/minxins';

.partners {
	position: relative;
	margin: auto;
	padding: 50px 0 0;

	.content {
		padding-top: 40px;
	}

	.list {
		position: relative;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: stretch;
		grid-gap: 25px;
		max-width: 400px;
		margin: 0 auto;
	}

	.item {
		background: #071c39;
		border-radius: 8px;
		border-image: $color-gradient-third;
		border-style: solid;
		border-width: 1px;
		box-shadow: 0 0 2px 2px #0b2556;

		figure {
			padding: 25px 20px;
			@include flex-center;
			height: 100px;
		}
		.info {
			padding: 25px 15px;
			border-top: 1px solid #0b2556;
			color: $color-white;
			height: 200px;
		}
		.info h4 {
			font-size: 1.3rem;
			font-weight: 700;
			margin-bottom: 10px;
			text-transform: uppercase;
			@include text-gradient;
		}
		.info p {
			font-weight: 300;
			font-size: 1.2rem;
			line-height: 1.8rem;
		}
	}

	@include maxw(374px) {
		.list {
			grid-template-columns: 180px;
			justify-content: center;
			grid-gap: 20px 0;
		}
		.item .info {
			padding: 25px 20px;
		}
	}

	@include minw(576px) {
		.content {
			padding-top: 70px;
		}
		.list {
			max-width: 500px;
		}
	}

	@include minw(768px) {
		.content {
			padding-top: 90px;
		}
		.list {
			max-width: 750px;
			grid-template-columns: repeat(3, 1fr);
			justify-content: center;
			grid-gap: 25px 30px;
		}
		.item figure {
			height: 140px;
		}

		.item .info {
			padding: 20px 15px;
			height: 200px;
			p {
				font-size: 1.2rem;
				line-height: 2.4rem;
			}
		}
	}

	@include minw(1024px) {
		padding: 80px 50px 0;
		.content {
			padding-top: 110px;
		}

		.list {
			max-width: 900px;
		}

		.item figure {
			height: 160px;
		}

		.item .info {
			padding: 30px 20px;
			height: 250px;
			p {
				font-size: 1.6rem;
			}
		}
	}

	@include minw(1440px) {
		padding: 120px 50px 0;
		.content {
			padding-top: 200px;
		}
		.list {
			max-width: 1014px;
		}

		.item figure {
			padding: 30px 40px;
		}
	}
}

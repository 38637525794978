@import '../../styles/minxins';
@import '../../styles/variables';

.heading {
	position: relative;
	max-width: 100%;
	height: 100%;
	&:before {
		content: '';
		position: absolute;
		background-image: url('../../assets/images/img/border.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: top center;
		width: 100%;
		top: 0;
		left: 0;
		height: 100%;
	}
}

.border {
	position: absolute;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
}
.title {
	padding-top: 0;
	h2 {
		text-align: center;
		font-size: 1.4rem;
		line-height: 4rem;
		text-transform: uppercase;
		@include text-gradient;
	}
}

@include maxw(340px) {
	.title h2 {
		line-height: 3rem;
		font-size: 1.1rem;
	}
}

@include minw(576px) {
	.title h2 {
		line-height: 6rem;
		font-size: 2rem;
	}
}

@include minw(768px) {
	.title {
		padding-top: 10px;
		h2 {
			font-size: 2.2rem;
		}
	}
}

@include minw(1024px) {
	.title h2 {
		line-height: 7rem;
		font-size: 2.8rem;
	}
}

@include minw(1440px) {
	.title {
		padding-top: 50px;
		h2 {
			font-size: 3.6rem;
		}
	}
}

@include minw(1920px) {
	.title {
		padding-top: 60px;
		h2 {
			font-size: 3.6rem;
		}
	}
}

@import '../../styles/minxins';

.header {
	@include minw(768px) {
		padding: 40px 0;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1000;
		transition: all ease-in-out 0.25s;
		&.scroll {
			background-color: #030f21;
			padding: 30px 0;
		}
	}
}

@import '../../styles/minxins';
@import '../../styles/variables';

.menu {
	.collapse,
	.hambugerBg {
		position: fixed;
		top: 1.5rem;
		right: 1.5rem;
		width: 40px;
		height: 40px;
		padding: 10px;
		border-radius: 5rem;
		background-color: #ffff;
		cursor: pointer;
		z-index: 20;
		-webkit-tap-highlight-color: transparent;
		@include flex-center;
	}
	.hambugerBg {
		z-index: 18;
		background-color: #030f21;
		transition: transform 0.25s linear;
	}
	.collapse {
		&.active + .hambugerBg {
			transform: scale(80);
		}
		&.active ~ .list {
			transform: translateX(0);
		}
	}

	.list {
		position: fixed;
		inset: 0;
		z-index: 19;
		gap: 55px;
		@include flex-col-center;
		transform: translateX(-100%);
		transition: transform 0.2s linear;
		-webkit-tap-highlight-color: transparent;
		.link {
			color: $color-white;
			font-size: $normal-font-size;
			font-weight: 400;
			line-height: 1.5rem;
			cursor: pointer;
		}
	}

	@include minw(576px) {
		.list .link {
			font-size: 2.5rem;
		}
	}

	@include minw(768px) {
		padding: 0;
		.list {
			transform: none;
			position: relative;
			flex-direction: row;
			padding: 0;
			.link {
				font-size: $small-font-size;
			}
		}
		.collapse,
		.hambugerBg {
			display: none;
		}
	}
}

@import '../../styles/variables';
@import '../../styles/minxins';

.portfolio {
  position: relative;
  padding-top: 50px;
  .border {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .content {
    padding-top: 50px;
    :global(.swiper-pagination) {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 15px;
    }
    :global(.swiper-pagination-bullet) {
      background: $color-gradient-primary-left;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      opacity: 1;
    }
    :global(.swiper-pagination-bullet-active) {
      width: 20px;
      height: 5px;
      border-radius: 0.5rem;
    }
  }

  .list {
    position: relative;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    grid-gap: 40px 20px;
    text-align: center;
    figure img {
      width: 100%;
      height: auto;
    }
  }

  @include minw(576px) {
    .content {
      padding-top: 60px;
    }
  }
  @include minw(768px) {
    .content {
      padding-top: 80px;
    }

    .list {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include minw(1024px) {
    padding: 80px 50px 0;
    .content {
      padding-top: 120px;
    }

    .list {
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: 80px;
    }
  }

  @include minw(1440px) {
    padding: 100px 50px 0;
    .content {
      padding-top: 200px;
    }
    .list {
      grid-gap: 40px 60px;
    }
  }
}

$Gotham: 'Gotham';
$SFDisplay: 'SF Pro Display', sans-serif;

@font-face {
	font-family: $Gotham;
	src: url('../assets/fonts/Gotham-Black.otf');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: $Gotham;
	src: url('../assets/fonts/GothamBold.ttf');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: $Gotham;
	src: url('../assets/fonts/GothamLight.ttf');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: $Gotham;
	src: url('../assets/fonts/Gotham-Book.woff'), url('../assets/fonts/Gotham-Book.woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: $Gotham;
	src: url('../assets/fonts/Gotham-Medium.woff'), url('../assets/fonts/Gotham-Medium.woff2');
	font-weight: normal;
	font-style: 400;
	font-display: swap;
}

/* Red Hat Display */

@font-face {
	font-family: 'Red Hat Display';
	src: url('/assets/fonts/RedHatDisplay-Light.ttf');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Red Hat Display';
	src: url('/assets/fonts/RedHatDisplay-Regular.ttf');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Red Hat Display';
	src: url('/assets/fonts/RedHatDisplay-Medium.ttf');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Red Hat Display';
	src: url('/assets/fonts/RedHatDisplay-SemiBold.ttf');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Red Hat Display';
	src: url('/assets/fonts/RedHatDisplay-Bold.ttf');
	font-weight: 700;
	font-style: normal;
}

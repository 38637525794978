@mixin breakpoint($point) {
	@if $point == 4k {
		@media (max-width: 160em) {
			@content;
		}
	} @else if $point == 3xl {
		@media (min-width: 120em) and (max-width: 160em) {
			@content;
		}
	} @else if $point == 2xl {
		// 1440px
		@media (min-width: 90em) {
			@content;
		}
	} @else if $point == xl {
		// 1120px
		@media (min-width: 70em) {
			@content;
		}
	} @else if $point == lg {
		// 1024px
		@media (min-width: 64em) {
			@content;
		}
	} @else if $point == md {
		// 768px
		@media (min-width: 48em) {
			@content;
		}
	} @else if $point == md-only {
		// 767px
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point == sm {
		// 600px
		@media (min-width: 37.5em) {
			@content;
		}
	} @else if $point == sm-only {
		@media (max-width: 599px) {
			@content;
		}
	} @else if $point == xs {
		@media (min-width: 360px) {
			@content;
		}
	} @else if $point == mobi-md {
		@media (min-width: 375px) and (max-width: 599px) {
			@content;
		}
	} @else if $point == mobi-sm {
		@media (min-width: 375px) {
			@content;
		}
	}
}

@mixin maxw($breakpoint) {
	@media (max-width: $breakpoint) {
		@content;
	}
}
@mixin minw($breakpoint) {
	@media (min-width: $breakpoint) {
		@content;
	}
}

@mixin bg-gradient {
	/* ff 3.6+ */
	background: -moz-linear-gradient(180deg, #ecc25f 0%, #49c4b4 100%);

	/* safari 5.1+,chrome 10+ */
	background: -webkit-linear-gradient(180deg, #ecc25f 0%, #49c4b4 100%);

	/* opera 11.10+ */
	background: -o-linear-gradient(180deg, #ecc25f 0%, #49c4b4 100%);

	/* ie 6-9 */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#49c4b4', endColorstr='#ECC25F', GradientType=1 );

	/* ie 10+ */
	background: -ms-linear-gradient(180deg, #ecc25f 0%, #49c4b4 100%);

	/* global 94%+ browsers support */
	background: $color-gradient-primary;
}

@mixin text-gradient {
	display: block;
	background: -moz-linear-gradient(180deg, #ecc25f 0%, #49c4b4 80%);
	background: -webkit-gradient(180deg, #ecc25f 0%, #49c4b4 80%);
	background: -webkit-linear-gradient(180deg, #ecc25f 0%, #49c4b4 80%);
	background: -o-linear-gradient(180deg, #ecc25f 0%, #49c4b4 80%);
	background: -ms-linear-gradient(180deg, #ecc25f 0%, #49c4b4 80%);
	background: linear-gradient(180deg, #ecc25f 0%, #49c4b4 80%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two',GradientType=1 );
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-ms-background-clip: text;
	-o-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
	-ms-text-fill-color: transparent;
	-o-text-fill-color: transparent;
	text-fill-color: transparent;
	opacity: 1;
	z-index: 9;
}
@mixin text-gradient-2 {
	background: $color-gradient-primary-v2;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	display: block;
}

@mixin flex-col-center {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@mixin flex-beetwen-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

@mixin flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

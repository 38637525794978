@import '../../styles/minxins';

.background {
  position: absolute;
  inset: 0;
  width: 100%;
  .first,
  .second,
  .third,
  .four {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    mix-blend-mode: color-dodge;
    width: 100%;
    height: 100%;
  }
  .first {
    background-image: url('../../assets/images/img/c.png');
    height: 340px;
  }
  .second {
    background-image: url('../../assets/images/img/d.png');
    height: 860px;
  }
  .third {
    background-image: url('../../assets/images/img/d.png');
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .four {
    background-image: url('../../assets/images/img/bg-galaxy-4.png');
  }

  .third,
  .four {
    height: 1050px;
  }

  @include maxw(340px) {
    .third,
    .four {
      height: 960px;
    }
  }

  @include minw(375px) {
    .second {
      height: 880px;
    }
    .third,
    .four {
      height: 750px;
    }
  }

  @include minw(576px) {
    .third,
    .four {
      height: 1043px;
    }
  }

  @include minw(600px) {
    .third,
    .four {
      height: 1055px;
    }
  }

  @include minw(768px) {
    .first {
      height: 558px;
    }
    .second {
      height: 1034px;
    }
    .third,
    .four {
      height: 935px;
    }
  }

  @include minw(1024px) {
    .first {
      height: 696px;
    }
    .second {
      height: 1355px;
    }
    .third,
    .four {
      height: 1520px;
    }
  }

  @include minw(1440px) {
    .first {
      height: 837px;
    }
    .second {
      height: 1600px;
    }
    .third {
      height: 1432px;
    }
    .four {
      height: 2000px;
    }
  }

  @include minw(1920px) {
    .first {
      height: 1200px;
    }
    .second {
      height: 2120px;
    }
    .third {
      height: 1900px;
    }
    .four {
      height: 120px;
    }
  }
}

/* --============ Colors ============-- */
$color-primary: #030f21;
$color-dark: #071c39;
$color-black: #000;
$color-white: #fff;
$color-gradient-primary: linear-gradient(180deg, #ecc25f 0%, #49c4b4 80%);
$color-gradient-primary-v2: linear-gradient(180deg, #ecc25f 10%, #49c4b4 80%);
$color-gradient-primary-left: linear-gradient(to left, #ecc25f 0%, #49c4b4 100%);
$color-gradient-secondary: linear-gradient(360deg, #03142f 0%, rgba(3, 20, 47, 0) 111.69%);
$color-gradient-third: linear-gradient(270.18deg, rgba(34, 70, 255, 0) 1%, #1a99a2 50%, rgba(34, 70, 255, 0) 100%) 100% 0 100% 0/1px 0 1px 0 stretch;

/* --============ Font and typography ============-- */

$biggest-font-size: 6rem;
$bigger-font-size: 5rem;
$big-font-size: 3rem;
$h2-font-size: 2.2rem;
$h3-font-size: 2rem;
$normal-font-size: 1.7rem;
$small-font-size: 1.5rem;
$smaller-font-size: 0.9rem;
$text-line-height: 3.2rem;

/* --============ Font weight ============-- */
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;

/* --============ Responsive Typography ============-- */
@media screen and (min-width: 968px) {
	$biggest-font-size: 9rem;
	$bigger-font-size: 6rem;
	$big-font-size: 3.6rem;
	$h2-font-size: 2.6rem;
	$h3-font-size: 2.4rem;
	$normal-font-size: 1.8rem;
	$small-font-size: 1.6rem;
	$smaller-font-size: 1.4rem;
	$text-line-height: 3.2rem;
}

html {
	font-size: 62.5%;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	padding: 0;
	margin: 0;
	font-family: 'Red Hat Display', sans-serif;
	color: $color-black;
	background-color: $color-primary;
	font-weight: $font-regular;
	font-size: $normal-font-size;
}

h1,
h2,
h3 {
	color: $color-white;
}

ul,
ol {
	list-style: none;
}

a {
	text-decoration: none;
}

button,
input {
	border: none;
	outline: none;
}

button {
	cursor: pointer;
}

img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

.p-relative {
	position: relative;
}

#root {
	overflow: hidden;
}

.container {
	max-width: 1470px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
}

@import '../../styles/variables';
@import '../../styles/minxins';

.footer {
	position: relative;
	display: flex;
	flex-direction: column;
	padding-top: 70px;
	text-align: center;
	align-items: center;
	user-select: none;
	gap: 15px 0;
	.logo {
		display: block;
		margin: auto;
		width: 60px;
	}

	.frame {
		position: relative;
		width: 100%;
	}

	.content {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		flex-direction: column;
		flex-wrap: wrap;
		padding: 15px 10px;
		width: 100%;
		z-index: 2;
		background: $color-gradient-secondary;
	}

	.list {
		display: flex;
		align-items: center;
		gap: 0 20px;
		margin-bottom: 15px;
	}
	.link {
		color: $color-white;
		font-size: 1.1rem;
		font-weight: 400;
		cursor: pointer;
	}
	.copyright {
		font-size: 1rem;
		line-height: 1.2rem;
		font-weight: 400;
		color: $color-white;
		text-align: center;
	}

	@include maxw(340px) {
		padding-top: 50px;
		.logo {
			width: 55px;
		}
	}

	@include minw(576px) {
		padding-top: 70px;
		.logo {
			width: 80px;
		}
		.content {
			padding: 35px 20px 20px;
		}
		.list {
			margin-bottom: 20px;
		}
		.link {
			font-size: 1.4rem;
		}
		.copyright {
			font-size: 1.2rem;
		}
	}

	@include minw(768px) {
		.content {
			padding: 50px 30px 20px;
			flex-direction: row;
			justify-content: space-between;
		}
		.list {
			margin-bottom: 0;
			gap: 0 20px;
		}
	}

	@include minw(1024px) {
		padding-top: 145px;
		.logo {
			width: 100px;
		}
		.content {
			padding: 35px 55px 20px;
		}
		.list {
			gap: 0 50px;
		}
		.link {
			font-size: 1.6rem;
		}
	}

	@include minw(1440px) {
		padding-top: 145px;
		gap: 30px 0;
		.logo {
			width: 128px;
		}
		.content {
			padding: 85px 95px 50px;
		}
		.list {
			gap: 0 75px;
		}
	}
}

@import '../../styles/variables';
@import '../../styles/minxins';

.contact {
  position: relative;
  margin: auto;
  max-width: 900px;
  padding-top: 60px;

  .content {
    display: grid;
    justify-content: center;
    align-items: baseline;
    grid-gap: 30px 0;
    margin: 0 30px;
  }
  .link {
    @include flex-col-center;

    figure {
      width: 40px;
      margin-bottom: 10px;
    }
    span {
      font-size: 1.2rem;
      display: block;
      font-weight: 700;
      @include text-gradient;
    }
  }

  @include maxw(340px) {
    .link figure {
      width: 30px;
    }
  }

  @include minw(576px) {
    padding-top: 100px;
    .content {
      max-width: 500px;
      margin: 0 auto;
    }
    .link {
      figure {
        width: 48px;
        margin-bottom: 20px;
      }
      span {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }
    }
  }

  @include minw(1024px) {
    padding-top: 150px;
    .content {
      grid-gap: 20px 0;
      max-width: 700px;
    }

    .link figure {
      width: 60px;
    }
  }

  @include minw(1440px) {
    padding-top: 240px;
  }
}

@import '../../styles/variables';
@import '../../styles/minxins';

.about {
	position: relative;
	margin: auto;
	z-index: 2;
	padding-top: 55px;
	.content {
		padding: 45px 20px 0;
	}
	:global(.swiper-pagination) {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0 15px;
	}
	:global(.swiper-pagination-bullet) {
		background: $color-gradient-primary-left;
		width: 5px;
		height: 5px;
		border-radius: 50%;
		opacity: 1;
	}
	:global(.swiper-pagination-bullet-active) {
		width: 20px;
		height: 5px;
		border-radius: 0.5rem;
	}
	.post {
		color: $color-white;
		cursor: pointer;
		@include flex-col-center;
		.title {
			font-size: 1.8rem;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: 30px;
			text-align: center;
			max-width: 200px;
		}
		.text {
			font-size: 1.1rem;
			line-height: 2rem;
			text-align: center;
			margin-bottom: 40px;
			font-weight: 300;
			max-width: 400px;
		}
	}

	@include maxw(340px) {
		padding-top: 15px;
		.post .text {
			text-align: justify;
		}
	}

	@include minw(375px) {
		padding-top: 31px;
	}

	@include minw(576px) {
		.content {
			padding: 50px 20px 0;
		}
		.post .title {
			max-width: 300px;
			font-size: 3rem;
			line-height: 4.5rem;
		}
		.post .text {
			font-size: 1.7rem;
			max-width: 600px;
			margin-bottom: 60px;
			line-height: 2.5rem;
		}
	}

	@include minw(768px) {
		padding-top: 40px;

		.post {
			padding-top: 60px;
			.title {
				max-width: 400px;
				font-size: 4rem;
				line-height: 5rem;
				margin-bottom: 60px;
			}
			.text {
				font-size: 2rem;
				line-height: 2.4rem;
				margin-bottom: 80px;
				max-width: 635px;
			}
		}
	}

	@include minw(1024px) {
		padding: 77px 50px 0;
		.post {
			padding-top: 110px;
			.title {
				font-size: 6rem;
				line-height: 7rem;
				margin-bottom: 90px;
				max-width: 666px;
			}
			.text {
				line-height: 3rem;
			}
		}
	}

	@include minw(1440px) {
		padding-top: 130px;
	}
}

@import '../../styles/variables';
@import '../../styles/minxins';

.banner {
	position: relative;
	width: 100%;
	padding-top: 50px;
	@include flex-col-center;
	&:before {
		content: '';
		position: absolute;
		inset: 0 8%;
		background: url('../../assets/images/img/spotlight.png') no-repeat;
		mix-blend-mode: color-dodge;
		background-size: contain;
	}
	.logo {
		position: relative;
		z-index: 10;
		width: 165px;
	}
	.content {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.title {
		position: relative;
		color: $color-white;
		text-transform: uppercase;
		text-align: center;
		user-select: none;
		z-index: 2;
		font-size: 2.8rem;
		max-width: 210px;
	}
	.layerWhale {
		position: absolute;
		top: 5%;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.layerWhale .maskFish {
		position: absolute;
		width: 100%;
		z-index: 1;
		transform: scale(1.3);
		mix-blend-mode: overlay;
	}
	.layerWhale .circle {
		position: absolute;
		top: -0.3%;
		left: 0;
		width: 100%;
		transform: scale(1.06);
	}
	.layerWhale .whale {
		position: relative;
		top: -0.75%;
		left: 0;
		z-index: 1;
		transform: scale(0.306);
		img {
			width: 100%;
		}
	}
	.empty {
		height: 170px;
	}
	.text {
		font-weight: 500;
		text-align: center;
		color: $color-white;
		z-index: 2;
		font-size: 0.9rem;
		line-height: 1.8rem;
		max-width: 280px;
	}

	@include maxw(340px) {
		.text {
			max-width: 240px;
		}
		.empty {
			height: 130px;
		}
	}

	@include minw(375px) {
		.layerWhale {
			top: 12%;
			.maskFish {
				transform: scale(1.5);
			}
			.circle {
				top: -0.3%;
				transform: scale(1.45);
			}
			.whale {
				top: -1.1%;
				transform: scale(0.425);
			}
		}
		.empty {
			height: 218px;
		}
	}

	@include minw(576px) {
		.logo {
			width: 190px;
		}
		.title {
			font-size: 4rem;
			max-width: 300px;
		}
		.layerWhale {
			top: 16%;
			.maskFish {
				transform: scale(1.7);
			}
			.circle {
				transform: scale(1.68);
			}
			.whale {
				top: -0.8%;
				transform: scale(0.49);
			}
		}
		.empty {
			height: 350px;
		}
		.text {
			font-size: 1.8rem;
			line-height: 3.5rem;
			max-width: 400px;
		}
	}

	@include minw(768px) {
		padding-top: 160px;
		.logo {
			width: 250px;
		}
		.title {
			font-size: 5rem;
			line-height: 6rem;
			max-width: 360px;
		}
		.layerWhale {
			display: flex;
			align-items: center;
			justify-content: center;
			top: 6%;
			.maskFish {
				transform: scale(1.5);
			}
			.circle {
				top: 6.27%;
				transform: scale(1.17);
			}
			.whale {
				transform: scale(0.57);
			}
		}
		.empty {
			height: 432px;
		}
		.text {
			font-size: 1.8rem;
			line-height: 4rem;
			max-width: 545px;
		}
	}

	@include minw(1024px) {
		.logo {
			width: 285px;
			margin-bottom: 40px;
		}
		.title {
			font-size: 8rem;
			line-height: 10rem;
			max-width: 740px;
		}
		.layerWhale {
			top: 8%;
			display: block;
			.maskFish {
				transform: scale(1.35);
			}
			.circle {
				top: 0;
				transform: scale(1.08);
			}
			.whale {
				top: -0.2%;
				width: 100%;
				transform: scale(0.317);
			}
		}
		.empty {
			height: 540px;
		}
		.text {
			font-size: 2.4rem;
			line-height: 4rem;
			max-width: 800px;
		}
	}

	@include minw(1440px) {
		padding-top: 150px;
		.title {
			font-size: 9rem;
		}
		.layerWhale {
			top: 20%;
			display: block;
			.maskFish {
				transform: scale(1.8);
			}
			.circle {
				transform: scale(1.45);
				top: -0.5%;
			}
			.whale {
				top: -0.7%;
				transform: scale(0.425);
			}
		}
		.empty {
			height: 770px;
		}
		.text {
			font-size: 2.4rem;
			line-height: 4rem;
			max-width: 800px;
		}
	}
}
